import { useMemo } from 'react';

import { DEFAULT_NETWORK, Network, NetworksID, NETWORKS } from 'src/constants';

import { useActiveWeb3React } from './useWeb3React';

type useNetwork = () => Network;

export const useNetwork: useNetwork = () => {
  const { chainId } = useActiveWeb3React();

  return useMemo(
    () => NETWORKS[(chainId as NetworksID) ?? DEFAULT_NETWORK],
    [chainId]
  );
};
