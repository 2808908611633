import { FC } from 'react';

import { ACPIManager } from './ACPIManager';
import { BlockManager } from './BlockManager';
import { ConnectProvider } from './ConnectProvider';
import { ContractsManager } from './ContractsManager';
import { LoaderManager } from './LoaderManager';

export const RootProvider: FC = ({ children }) => {
  return (
    <>
      <LoaderManager />
      <ConnectProvider>
        <BlockManager />
        <ContractsManager />
        <ACPIManager />
        {children}
      </ConnectProvider>
    </>
  );
};
