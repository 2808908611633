import { FC, useEffect } from 'react';
import { Box, Heading, Stack, StackItem, Text } from '@chakra-ui/react';
import { useTimer } from 'react-timer-hook';
import { useAppSelector } from 'src/hooks';
import { utilsSelectors } from 'src/slices';

type CountdownCell = { value: number; name: string };
type Countdown = {
  title: string;
  timestamp: Date;
};

const CountdownCell: FC<CountdownCell> = ({ value, name }) => {
  return (
    <StackItem display={'flex'} alignItems={'baseline'}>
      <Heading size={'xl'} color={'brand.400'}>
        {value}
      </Heading>
      <Heading size={'md'}>{name}</Heading>
    </StackItem>
  );
};

export const Countdown: FC<Countdown> = ({ title, timestamp }) => {
  const { hours, minutes, seconds, restart } = useTimer({
    expiryTimestamp: timestamp,
  });

  const blockNumber = useAppSelector(utilsSelectors.getBlockNumber);

  useEffect(() => {
    restart(timestamp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timestamp]);

  return (
    <Box maxW={'sm'} overflow={'hidden'}>
      <Heading size={'lg'}>{title}</Heading>
      <Stack isInline={true} spacing={3}>
        <CountdownCell name={'Hours'} value={hours} />
        <CountdownCell name={'Mins'} value={minutes} />
        <CountdownCell name={'Secs'} value={seconds} />
      </Stack>
      <Text fontStyle={'italic'}>{`Block: ${blockNumber}`}</Text>
    </Box>
  );
};
