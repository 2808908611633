import { REALT, TypedEvent } from 'src/abis';
import { ACPISType } from 'src/constants';

type getEventTimestamp = (
  events: TypedEvent[],
  index?: number
) => Promise<number>;

export const getEventTimestamp: getEventTimestamp = async (
  events,
  index = 1
) => {
  if (!events.length) return 0;

  const lastBlock = await events[events.length - index].getBlock();
  return lastBlock.timestamp;
};

type getRoundStartedAt = (
  acpiContract: ACPISType,
  mainContract: REALT
) => Promise<number>;

export const getRoundStartedAt: getRoundStartedAt = async (
  acpiContract,
  mainContract
) => {
  const acpiChangedFilter = mainContract.filters.ACPIChanged();
  const roundWinFilter = acpiContract.filters.RoundWin();

  try {
    const [acpiChangedEvents, roundWinEvents] = await Promise.all([
      acpiContract.queryFilter(roundWinFilter),
      mainContract.queryFilter(acpiChangedFilter),
    ]);

    const [lastRoundWinTimestamp, lastAcpiChangedTimestamp] = await Promise.all(
      [getEventTimestamp(roundWinEvents), getEventTimestamp(acpiChangedEvents)]
    );

    return lastRoundWinTimestamp > lastAcpiChangedTimestamp
      ? lastRoundWinTimestamp
      : lastAcpiChangedTimestamp;
  } catch (error) {
    console.warn(error);
    return 0;
  }
};
