import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';

import { utilsReducer, contractsReducer, acpiOneReducer } from 'src/slices';

export const store = configureStore({
  reducer: {
    utils: utilsReducer,
    contracts: contractsReducer,
    acpiOne: acpiOneReducer,
  },
  middleware: [logger],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
