import { FC } from 'react';
import { Box, Flex, Heading, Image, Spacer, Stack } from '@chakra-ui/react';

import { RealTPNG } from 'src/assets';

import { HeaderMenu } from '../HeaderMenu';
import { Network } from '../Network.ts/Network';
import { WalletInfo } from '../WalletInfo';

export const Header: FC = () => {
  return (
    <Flex direction={'column'}>
      <Flex
        alignItems={'center'}
        paddingX={{ base: 3, md: 5 }}
        paddingY={{ base: 3, md: 2 }}
        backgroundColor={'gray.700'}
      >
        <Image
          src={RealTPNG}
          boxSize={{ base: '40px', md: '75px' }}
          objectFit={'fill'}
          alt={'Logo'}
        />
        <Heading
          size={'lg'}
          letterSpacing={'tighter'}
          display={{ base: 'none', md: 'flex' }}
        >
          {'ACPI'}
        </Heading>
        <Spacer />
        <Stack isInline={true}>
          <Network />
          <WalletInfo />
          <HeaderMenu />
        </Stack>
      </Flex>
      <Box
        h={'5px'}
        w={'full'}
        bgGradient={'linear(to-r, brand.200, brand.500)'}
      />
    </Flex>
  );
};
