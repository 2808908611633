import { FC } from 'react';
import {
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  ModalProps,
  UseDisclosureReturn,
} from '@chakra-ui/react';

import { LoaderPNG } from 'src/assets';
import { useAppSelector } from 'src/hooks';
import { utilsSelectors } from 'src/slices';

type Loader = {
  disclosure: UseDisclosureReturn;
  modalOptions?: Partial<ModalProps>;
};

export const Loader: FC<Loader> = ({ disclosure, modalOptions }) => {
  const loadingSubtitle = useAppSelector(utilsSelectors.getLoaderSubtitle);

  return (
    <Modal
      isOpen={disclosure.isOpen}
      onClose={disclosure.onClose}
      isCentered={true}
      closeOnOverlayClick={false}
      autoFocus={false}
      returnFocusOnClose={false}
      {...modalOptions}
    >
      <ModalOverlay backdropFilter={'blur(10px)'} />
      <ModalContent
        width={'auto'}
        background={'none'}
        shadow={'none'}
        justifyContent={'center'}
      >
        <ModalBody justifyContent={'center'}>
          <Image src={LoaderPNG} boxSize={{ base: 150, md: 300 }} />
        </ModalBody>
        {loadingSubtitle && (
          <ModalFooter justifyContent={'center'}>
            <Heading
              size={'lg'}
              isTruncated={true}
              bgGradient={'linear(to-r, brand.200, brand.600)'}
              bgClip={'text'}
            >
              {loadingSubtitle}
            </Heading>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};
