import { FC } from 'react';
import {
  Box,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
} from '@chakra-ui/react';

type BidInfo = { title: string; value: string; subtitle?: string };

export const BidInfo: FC<BidInfo> = ({ title, value, subtitle }) => {
  return (
    <Box width={'100%'}>
      <Box
        py={2}
        px={3}
        borderWidth={'thin'}
        borderRadius={'xl'}
        backgroundColor={'gray.400'}
        boxShadow={'0px 0px 3px 1px #f2ac26'}
      >
        <Stat textAlign={'center'}>
          <StatLabel>{title}</StatLabel>
          <StatNumber isTruncated={true}>{value}</StatNumber>
          {subtitle && <StatHelpText mb={0}>{subtitle}</StatHelpText>}
        </Stat>
      </Box>
    </Box>
  );
};
