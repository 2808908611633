import { FC } from 'react';
import { Circle, Icon, SquareProps } from '@chakra-ui/react';

import { TOKENS, TokensID } from 'src/constants';

type TokenIcon = {
  tokenId: TokensID;
  containerProps?: SquareProps;
};

const containerPropsDefault = {
  size: '3rem',
  mr: 3,
};

export const TokenIcon: FC<TokenIcon> = ({
  tokenId,
  containerProps = containerPropsDefault,
}) => {
  return (
    <Circle
      color={'white'}
      backgroundColor={'brand.800'}
      borderColor={'brand.400'}
      borderWidth={'thin'}
      p={TOKENS[tokenId].padding}
      {...containerProps}
    >
      <Icon as={TOKENS[tokenId].svg} boxSize={'full'} />
    </Circle>
  );
};
