import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Heading } from '@chakra-ui/react';
import { BigNumber } from '@ethersproject/bignumber';

import { useAppSelector } from 'src/hooks';
import { contractsSelectors } from 'src/slices';
import { formatBN } from 'src/utils';

type ACPIPrice = {
  symbol: string;
};

export const ACPIPrice: FC<ACPIPrice> = ({ symbol }) => {
  const { acpiContract } = useAppSelector(contractsSelectors.getContracts);
  const [price, setPrice] = useState<BigNumber>(BigNumber.from(0));
  const { t } = useTranslation();

  useEffect(() => {
    const getACPIPrice = async () => {
      try {
        const price = await acpiContract?.acpiPrice();
        if (price) {
          setPrice(price);
        }
      } catch (error) {
        console.warn(error);
      }
    };
    getACPIPrice();
  }, [acpiContract]);

  return (
    <Box maxW={'sm'} overflow={'hidden'}>
      <Heading size={'lg'}>{t('acpiIsOver')}</Heading>
      <Heading size={'md'}>
        {t('estimatePrice', { price: formatBN(price), symbol: symbol })}
      </Heading>
    </Box>
  );
};
