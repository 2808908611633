import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers';

type getSignerOrProvider = (
  library: Web3Provider,
  account?: string
) => Web3Provider | JsonRpcSigner;

export const getSignerOrProvider: getSignerOrProvider = (library, account) => {
  return account ? library.getSigner(account).connectUnchecked() : library;
};
