import { asConst } from 'src/utils';

export enum LinksID {
  Wallet,
  Blockchain,
  Avalanche,
  Binance,
  Ethereum,
  Gnosis,
  POA,
  Polygon,
  xDai,
  REG,
  Twitter,
  Github,
  Telegram,
  Medium,
}

export type Link = { text: string; href: string };

export const LINKS = asConst<Record<LinksID, Link>>()({
  [LinksID.Wallet]: {
    text: 'link.wallet.text',
    href: 'link.wallet.href',
  },
  [LinksID.Blockchain]: {
    text: 'link.blockchain.text',
    href: 'link.blockchain.href',
  },
  [LinksID.Avalanche]: {
    text: 'avalanche',
    href: 'https://www.avax.network/',
  },
  [LinksID.Binance]: {
    text: 'Binance',
    href: 'https://www.binance.com/',
  },
  [LinksID.Ethereum]: {
    text: 'Ethereum',
    href: 'https://ethereum.org/',
  },
  [LinksID.Gnosis]: {
    text: 'Gnosis Chain',
    href: 'https://www.xdaichain.com/',
  },
  [LinksID.POA]: {
    text: 'POA Network',
    href: 'https://www.poa.network/',
  },
  [LinksID.Polygon]: {
    text: 'Polygon',
    href: 'https://polygon.technology/',
  },
  [LinksID.xDai]: {
    text: 'xDai',
    href: 'https://www.xdaichain.com/',
  },
  [LinksID.REG]: {
    text: 'link.reg.text',
    href: 'link.reg.href',
  },
  [LinksID.Twitter]: {
    text: 'Twitter',
    href: 'https://twitter.com/RealTPlatform',
  },
  [LinksID.Github]: {
    text: 'GitHub',
    href: 'https://github.com/real-token/Realtoken-protocol-v1-contract-step0',
  },
  [LinksID.Telegram]: {
    text: 'Telegram',
    href: 'https://t.me/Realtoken_welcome',
  },
  [LinksID.Medium]: {
    text: 'Medium',
    href: 'link.medium.href',
  },
} as const);
