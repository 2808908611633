import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToast } from '@chakra-ui/react';
import { BigNumber } from '@ethersproject/bignumber';

import { TOASTS, ToastsID } from 'src/constants';
import { useActiveWeb3React, useAppDispatch, useAppSelector } from 'src/hooks';
import {
  acpiOneActions,
  acpiOneSelectors,
  contractsSelectors,
  utilsSelectors,
} from 'src/slices';
import {
  formatAddress,
  formatBN,
  getRoundStartedAt,
  getToast,
} from 'src/utils';

type ACPIOneEvents = {
  setRefresh: (boolean: boolean) => void;
};

const ACPIOneEvents: FC<ACPIOneEvents> = ({ setRefresh }) => {
  const dispatch = useAppDispatch();
  const { acpiContract } = useAppSelector(contractsSelectors.getContracts);
  const { currentRound, totalRound } = useAppSelector(
    acpiOneSelectors.getACPIOne
  );
  const { account } = useActiveWeb3React();

  const toast = useToast();
  const { t } = useTranslation();

  useEffect(() => {
    const onRoundWin = (highestBidder: string, highestBid: BigNumber) => {
      toast(
        highestBidder === account
          ? getToast(ToastsID.onRoundWin, t, {
              description: t(TOASTS[ToastsID.onRoundWin].description, {
                bid: formatBN(highestBid),
              }),
            })
          : getToast(ToastsID.onRoundEnd, t, {
              description: t(TOASTS[ToastsID.onRoundEnd].description, {
                bidder: formatAddress(highestBidder),
                bid: formatBN(highestBid),
              }),
            })
      );

      setRefresh(true);

      toast(
        currentRound === totalRound
          ? getToast(ToastsID.onACPIEnd, t)
          : getToast(ToastsID.onNewRound, t)
      );
    };

    acpiContract?.on('RoundWin', onRoundWin);

    return () => {
      acpiContract?.off('RoundWin', onRoundWin);
    };
  });

  useEffect(() => {
    const onBidEvent = (highestBidder: string, highestBid: BigNumber) => {
      dispatch(
        acpiOneActions.setBidEvent({
          highestBidder: highestBidder,
          highestBid: highestBid,
          actualBid: account === highestBidder ? highestBid : undefined,
        })
      );

      toast(
        highestBidder === account
          ? getToast(ToastsID.onOwnBid, t, {
              description: t(TOASTS[ToastsID.onOwnBid].description, {
                bid: formatBN(highestBid),
              }),
            })
          : getToast(ToastsID.onOtherBid, t, {
              description: t(TOASTS[ToastsID.onOtherBid].description, {
                bidder: formatAddress(highestBidder),
                bid: formatBN(highestBid),
              }),
            })
      );
    };

    acpiContract?.on('Bid', onBidEvent);

    return () => {
      acpiContract?.off('Bid', onBidEvent);
    };
  }, [dispatch, acpiContract, account, toast, t]);

  return null;
};

export const ACPIOneManager: FC = () => {
  const dispatch = useAppDispatch();
  const { account } = useActiveWeb3React();
  const [shouldRefresh, setRefresh] = useState<boolean>(false);

  const { mainContract, acpiContract } = useAppSelector(
    contractsSelectors.getACPIOneContracts
  );
  const disclosure = useAppSelector(utilsSelectors.getDisclosure);

  useEffect(() => {
    const setACPIOne = async () => {
      disclosure.onOpen();
      if (!mainContract || !acpiContract) return;

      const acpiPromises = [
        acpiContract.bidIncrement(),
        acpiContract.highestBid(),
        acpiContract.highestBidder(),
        acpiContract.roundTime(),
        acpiContract.currentRound(),
        acpiContract.totalRound(),
        acpiContract.totalWins(),
        getRoundStartedAt(acpiContract, mainContract),
        acpiContract.signer ? acpiContract.getBid() : BigNumber.from(0),
        account ? acpiContract.pendingWins(account) : BigNumber.from(0),
        account ? acpiContract.pendingReturns(account) : BigNumber.from(0),
      ] as const;

      const acpiOneMethods = await Promise.all(acpiPromises);

      dispatch(
        acpiOneActions.setACPIOne({
          bidIncrement: acpiOneMethods[0],
          highestBid: acpiOneMethods[1],
          highestBidder: acpiOneMethods[2],
          roundTime: acpiOneMethods[3],
          currentRound: acpiOneMethods[4],
          totalRound: acpiOneMethods[5],
          totalWins: acpiOneMethods[6],
          roundStartedAt: acpiOneMethods[7],
          actualBid: acpiOneMethods[8],
          pendingWins: acpiOneMethods[9],
          pendingReturns: acpiOneMethods[10],
        })
      );
    };

    setACPIOne().then(() => {
      setRefresh(false);
      disclosure.onClose();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, acpiContract, mainContract, shouldRefresh]);

  return (
    <>
      <ACPIOneEvents setRefresh={setRefresh} />
    </>
  );
};
