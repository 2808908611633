import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BigNumber } from '@ethersproject/bignumber';

export type ACPIOneState = {
  actualBid: BigNumber;
  bidIncrement: BigNumber;
  highestBid: BigNumber;
  highestBidder: string;
  roundTime: BigNumber;
  currentRound: number;
  totalRound: number;
  totalWins: BigNumber;
  roundStartedAt: number;
  pendingWins: BigNumber;
  pendingReturns: BigNumber;
};

const initialState: ACPIOneState = {
  actualBid: BigNumber.from(0),
  bidIncrement: BigNumber.from(0),
  highestBid: BigNumber.from(0),
  highestBidder: '',
  roundTime: BigNumber.from(0),
  currentRound: 0,
  totalRound: 0,
  totalWins: BigNumber.from(0),
  roundStartedAt: 0,
  pendingWins: BigNumber.from(0),
  pendingReturns: BigNumber.from(0),
};

const acpiOneSlice = createSlice({
  name: 'acpiOne',
  initialState,
  reducers: {
    setACPIOne: (_state, action: PayloadAction<ACPIOneState>) => action.payload,
    setBidEvent: (
      state,
      action: PayloadAction<
        Pick<ACPIOneState, 'highestBid' | 'highestBidder'> &
          Pick<Partial<ACPIOneState>, 'actualBid'>
      >
    ) => {
      if (action.payload.actualBid) {
        state.actualBid = action.payload.actualBid;
      }
      state.highestBidder = action.payload.highestBidder;
      state.highestBid = action.payload.highestBid;
    },
    resetACPIOne: () => initialState,
  },
});

const stateSelector = createSelector(
  ({ acpiOne }: { acpiOne: ACPIOneState }) => acpiOne,
  (state) => state
);

export const acpiOneSelectors = {
  getACPIOne: createSelector(stateSelector, (state) => {
    const isRoundOver =
      new Date((state.roundTime.toNumber() + state.roundStartedAt) * 1000) <
      new Date();

    return {
      ...state,
      minBid: state.highestBid.add(state.bidIncrement).sub(state.actualBid),
      isOver: state.currentRound === state.totalRound,
      isRoundOver: isRoundOver,
    };
  }),
};

export const { actions: acpiOneActions, reducer: acpiOneReducer } =
  acpiOneSlice;
