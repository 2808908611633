import { FC } from 'react';
import { Container, Flex, Heading, Image, Stack } from '@chakra-ui/react';

import { Divider, Header, MyPosition, RoundInfo } from 'src/components';
import { useAppSelector } from './hooks';
import { contractsSelectors } from './slices';
import { ACPISOff } from './constants';
import { useTranslation } from 'react-i18next';
import { LoaderPNG } from './assets';

export const Body: FC = () => {
  const { currentACPI } = useAppSelector(contractsSelectors.getContracts);
  const { t } = useTranslation();

  if (currentACPI === ACPISOff.Zero) {
    return (
      <Flex grow={1} p={{ base: '1.5rem', sm: '2.5rem', md: '3.5rem' }}>
        <Stack
          boxSize={'full'}
          borderRadius={'xl'}
          justify={'center'}
          align={'center'}
        >
          <Image src={LoaderPNG} boxSize={{ base: 150, md: 300 }} />
          <Heading>{t('comingSoon')}</Heading>
        </Stack>
      </Flex>
    );
  }

  return (
    <Flex grow={1} p={{ base: '1.5rem', sm: '2.5rem', md: '3.5rem' }}>
      <Stack
        boxSize={'full'}
        bgColor={'gray.700'}
        borderRadius={'xl'}
        direction={{ base: 'column', md: 'row' }}
        divider={<Divider />}
      >
        <MyPosition />
        <RoundInfo />
      </Stack>
    </Flex>
  );
};

export const App: FC = () => {
  return (
    <Container maxW={'100vw'} maxH={'100vh'} m={0} p={0}>
      <Flex h={{ base: 'auto', md: '100vh' }} direction={'column'}>
        <Header />
        <Body />
      </Flex>
    </Container>
  );
};
