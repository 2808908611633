import { FC, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  GridItem,
  Heading,
  SimpleGrid,
  useToast,
  Text,
  Box,
  Stack,
} from '@chakra-ui/react';
import { parseUnits } from '@ethersproject/units';
import { BigNumber } from '@ethersproject/bignumber';

import { REG_SYMBOL, ToastsID } from 'src/constants';
import { useActiveWeb3React, useAppSelector, useNetwork } from 'src/hooks';
import {
  acpiOneSelectors,
  contractsSelectors,
  utilsSelectors,
} from 'src/slices';
import { formatBN, getPrice, getToast } from 'src/utils';

import { BidInfo } from './BidInfo';
import { ConnectButton } from './ConnectButton';
import { InputBid, InputBidForm } from './InputBid';

export const MyPosition: FC = () => {
  const { account } = useActiveWeb3React();
  const { acpiContract } = useAppSelector(contractsSelectors.getContracts);
  const {
    actualBid,
    highestBidder,
    currentRound,
    pendingWins,
    pendingReturns,
    minBid,
    isOver,
    isRoundOver,
  } = useAppSelector(acpiOneSelectors.getACPIOne);
  const balance = useAppSelector(utilsSelectors.getBalance);
  const price = useAppSelector(utilsSelectors.getPrice);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [bid, setBid] = useState<BigNumber>(minBid);

  const { symbol } = useNetwork();

  const toast = useToast();
  const { t } = useTranslation();

  const onBid = useCallback(async () => {
    setLoading(true);
    try {
      await acpiContract?.bid(currentRound, { value: bid });
      toast(getToast(ToastsID.onBidSuccess, t));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      toast(
        error?.code === 4001
          ? getToast(ToastsID.onBidWarning, t)
          : getToast(ToastsID.onBidError, t, {
              description: error?.error?.message,
            })
      );
    } finally {
      setLoading(false);
    }
  }, [currentRound, acpiContract, bid, t, toast]);

  const validate = {
    isValidBigNumber: (value: string) => {
      try {
        parseUnits(value);
        return true;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        return t('invalidBigNumber');
      }
    },
    isSufficientFunds: (value: string) =>
      balance.gte(parseUnits(value)) || t('insufficientFunds'),
    isHighEnough: (value: string) =>
      minBid.lte(parseUnits(value)) || t('bidTooLow'),
  };

  const {
    control,
    resetField,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<InputBidForm>({
    mode: 'onChange',
    defaultValues: { inputBid: formatBN(minBid) },
  });

  const onValid = useCallback((userInput: string) => {
    setBid(parseUnits(userInput));
  }, []);

  const onReset = useCallback(() => {
    resetField('inputBid', { defaultValue: formatBN(minBid) });
  }, [resetField, minBid]);

  useEffect(() => {
    onReset();
  }, [onReset]);

  return (
    <Stack
      boxSize={'full'}
      p={{ base: 5, md: 10 }}
      spacing={10}
      align={'center'}
      justify={'center'}
    >
      <Heading size={'2xl'}>{t('actualPosition')}</Heading>
      <SimpleGrid columns={2} columnGap={3} rowGap={6} w={'full'}>
        <GridItem colSpan={{ base: 2, xl: 1 }}>
          <BidInfo
            title={t('actualBid')}
            value={formatBN(actualBid)}
            subtitle={`~${formatBN(
              getPrice(actualBid, price),
              undefined,
              1e16,
              true
            )}$`}
          />
        </GridItem>
        <GridItem colSpan={{ base: 2, xl: 1 }}>
          <BidInfo
            title={t('newBid')}
            value={formatBN(actualBid.add(bid))}
            subtitle={`~${formatBN(
              getPrice(actualBid.add(bid), price),
              undefined,
              1e16,
              true
            )}$`}
          />
        </GridItem>
        <GridItem colSpan={2}>
          <InputBid
            hooksProps={{
              control: control,
              name: 'inputBid',
              rules: { validate: validate },
            }}
            onValid={onValid}
            onReset={onReset}
            symbol={symbol.name}
          />
          <Text textAlign={'right'} mt={1} fontSize={'sm'}>
            {t('dollarsValue', {
              dollarsValue: formatBN(
                getPrice(bid, price),
                undefined,
                1e16,
                true
              ),
            })}
          </Text>
        </GridItem>
        <GridItem colSpan={2}>
          {account ? (
            <Button
              isFullWidth={true}
              isLoading={isLoading}
              loadingText={t('sending')}
              type={'submit'}
              variant={isOver ? 'solid' : 'branded'}
              disabled={isOver || isLoading || !isValid}
              onClick={handleSubmit(onBid)}
            >
              <Text isTruncated={true}>
                {errors?.inputBid?.message ??
                  t(
                    isOver
                      ? 'toast.onACPIEnd.title'
                      : highestBidder === account
                      ? 'alreadyHighestBidder'
                      : isRoundOver
                      ? 'roundIsOver'
                      : 'send'
                  )}
              </Text>
            </Button>
          ) : (
            <ConnectButton />
          )}
        </GridItem>
        <GridItem colSpan={2}>
          <Box maxW={'sm'} overflow={'hidden'}>
            <Stack spacing={2}>
              <Text fontSize={'sm'}>
                {t('pendingWins', {
                  pendingWins: formatBN(pendingWins),
                  symbol: REG_SYMBOL,
                })}
              </Text>
              <Text fontSize={'sm'}>
                {t('pendingReturns', {
                  pendingReturns: formatBN(pendingReturns),
                  symbol: symbol.name,
                  dollarsValue: formatBN(
                    getPrice(pendingReturns, price),
                    undefined,
                    1e16,
                    true
                  ),
                })}
              </Text>
            </Stack>
          </Box>
        </GridItem>
      </SimpleGrid>
    </Stack>
  );
};
