import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { MenuItem } from '@chakra-ui/react';

import { LINKS, LinksID } from 'src/constants';
import { useBlockExplorer } from 'src/hooks';

import { LinkAs } from '../LinkAs';

import { CommandLink } from './CommandLink';

export const MenuHeader: FC = () => {
  const blockExplorer = useBlockExplorer();
  const { t } = useTranslation();

  return (
    <>
      <LinkAs
        link={{
          text: t(LINKS[LinksID.REG].text),
          href: t(LINKS[LinksID.REG].href),
        }}
        isIcon={false}
        as={MenuItem}
        command={<CommandLink icon={<ExternalLinkIcon />} />}
      />
      <LinkAs
        link={blockExplorer}
        isIcon={false}
        as={MenuItem}
        command={<CommandLink icon={<ExternalLinkIcon />} />}
      />
    </>
  );
};
