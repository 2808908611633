import { FC, useCallback, useEffect } from 'react';
import { useController, UseControllerProps, useWatch } from 'react-hook-form';
import {
  FormControl,
  IconButton,
  InputRightElement,
  NumberInput,
  NumberInputField,
} from '@chakra-ui/react';
import { RepeatIcon } from '@chakra-ui/icons';

import { useActiveWeb3React } from 'src/hooks';
import { isInputValid } from 'src/utils';

export type InputBidForm = {
  inputBid: string;
};

type InputBid = {
  hooksProps: UseControllerProps<InputBidForm>;
  symbol: string;
  onValid: (bid: string) => void;
  onReset: () => void;
};

export const InputBid: FC<InputBid> = ({
  hooksProps,
  onReset,
  onValid,
  symbol,
}) => {
  const { account } = useActiveWeb3React();
  const {
    formState: { isValid, isValidating },
    field: { ref, onChange, ...restField },
  } = useController(hooksProps);

  const userInput = useWatch({
    name: hooksProps.name,
    control: hooksProps.control,
    defaultValue: hooksProps.defaultValue,
  });

  const onEnforcedChange = useCallback(
    (nextUserInput: string) => {
      if (isInputValid(nextUserInput)) {
        onChange(nextUserInput);
      }
    },
    [onChange]
  );

  useEffect(() => {
    if (!isValidating && isValid) {
      onValid(userInput);
    }
  }, [isValid, isValidating, userInput, onValid]);

  return (
    <FormControl isInvalid={!!account && !isValid}>
      <NumberInput
        size={'lg'}
        min={0}
        precision={8}
        clampValueOnBlur={true}
        onChange={onEnforcedChange}
        {...restField}
      >
        <InputRightElement width={'3.5rem'}>
          <IconButton
            aria-label={'reloadBid'}
            resize={'vertical'}
            size={'sm'}
            variant={'branded'}
            onClick={onReset}
            icon={<RepeatIcon />}
          />
        </InputRightElement>
        <InputRightElement pr={'4.5rem'} pointerEvents={'none'}>
          {symbol}
        </InputRightElement>
        <NumberInputField
          textAlign={'right'}
          borderRadius={'xl'}
          pr={'6.5rem'}
          backgroundColor={'gray.400'}
          boxShadow={'0px 0px 3px 1px #f2ac26'}
          placeholder={hooksProps.defaultValue}
          ref={ref}
          name={restField.name}
        />
      </NumberInput>
    </FormControl>
  );
};
