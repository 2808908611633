import { Interface } from '@ethersproject/abi';
import { isAddress } from '@ethersproject/address';
import { AddressZero } from '@ethersproject/constants';
import { Contract } from '@ethersproject/contracts';
import { Web3Provider } from '@ethersproject/providers';

import { getSignerOrProvider } from './getSignerOrProvider';

export const getContract = <T extends Contract = Contract>(
  address: string,
  abi: Interface,
  library?: Web3Provider,
  account?: string
): T | undefined => {
  if (!isAddress(address) || address === AddressZero || !library) {
    return undefined;
  }
  return new Contract(address, abi, getSignerOrProvider(library, account)) as T;
};
