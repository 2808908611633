import { FC } from 'react';
import { Heading, Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { useActiveWeb3React, useAppSelector, useNetwork } from 'src/hooks';
import { acpiOneSelectors, contractsSelectors } from 'src/slices';
import { formatAddress, formatBN } from 'src/utils';

import { InAuction } from './InAuction';
import { HighestBid } from './HighestBid';
import { Countdown } from './Countdown';
import { ACPIPrice } from './ACPIPrice';

export const RoundInfo: FC = () => {
  const { currentACPI } = useAppSelector(contractsSelectors.getContracts);
  const { account } = useActiveWeb3React();
  const {
    highestBid,
    highestBidder,
    roundStartedAt,
    roundTime,
    currentRound,
    totalRound,
  } = useAppSelector(acpiOneSelectors.getACPIOne);

  const { symbol, tokenId } = useNetwork();

  const { t } = useTranslation();

  const roundEndAt = new Date((roundTime.toNumber() + roundStartedAt) * 1000);
  const isOver = currentRound >= totalRound;

  return (
    <Stack
      boxSize={'full'}
      p={{ base: 5, md: 10 }}
      spacing={10}
      align={'flex-start'}
    >
      <Stack spacing={1} justifySelf={'flex-start'}>
        <Heading size={'2xl'}>{t('roundInfo')}</Heading>
        <Heading size={'md'}>{`ACPI #${currentACPI}`}</Heading>
      </Stack>
      <InAuction title={t('inAuction')} amount={1} />
      <HighestBid
        title={t('highestBid')}
        tokenId={tokenId}
        symbol={symbol.name}
        highestBid={formatBN(highestBid)}
        highestBidder={
          account === highestBidder
            ? t('bestOffer')
            : formatAddress(highestBidder)
        }
      />
      {isOver ? (
        <ACPIPrice symbol={symbol.name} />
      ) : (
        <Countdown
          title={t('countdown', {
            round: { current: currentRound + 1, total: totalRound },
          })}
          timestamp={roundEndAt}
        />
      )}
    </Stack>
  );
};
