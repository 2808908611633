import { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';

import { useNetwork } from 'src/hooks';

export const Network: FC = () => {
  const { error } = useWeb3React();
  const { name } = useNetwork();

  const isUnsupportedChainIdError = error instanceof UnsupportedChainIdError;

  if (isUnsupportedChainIdError) {
    return null;
  }

  return (
    <Flex
      alignItems={'center'}
      borderRadius={'xl'}
      borderWidth={'thin'}
      paddingX={3}
      display={{ base: 'none', md: 'flex' }}
    >
      <Text size={'lg'}>{name}</Text>
    </Flex>
  );
};
