import { UseToastOptions } from '@chakra-ui/react';

import { asConst } from 'src/utils';

export enum ToastsID {
  onACPIChanged,
  onBidSuccess,
  onBidWarning,
  onBidError,
  onOwnBid,
  onOtherBid,
  onRoundWin,
  onRoundEnd,
  onNewRound,
  onACPIEnd,
}

export const TOASTS = asConst<Record<ToastsID, UseToastOptions>>()({
  [ToastsID.onACPIChanged]: {
    title: 'toast.onACPIChanged.title',
    description: 'toast.onACPIChanged.description',
    status: 'success',
    duration: 15000,
    isClosable: true,
    position: 'bottom-right',
  },
  [ToastsID.onBidSuccess]: {
    title: 'toast.onBidSuccess.title',
    description: 'toast.onBidSuccess.description',
    status: 'success',
    duration: 9000,
    isClosable: true,
    position: 'bottom-right',
  },
  [ToastsID.onBidWarning]: {
    title: 'toast.onBidWarning.title',
    description: 'toast.onBidWarning.description',
    status: 'warning',
    duration: 9000,
    isClosable: true,
    position: 'bottom-right',
  },
  [ToastsID.onBidError]: {
    title: 'toast.onBidError.title',
    description: 'toast.onBidError.description',
    status: 'error',
    duration: 9000,
    isClosable: true,
    position: 'bottom-right',
  },
  [ToastsID.onOwnBid]: {
    title: 'toast.onOwnBid.title',
    description: 'toast.onOwnBid.description',
    status: 'success',
    duration: 9000,
    isClosable: true,
    position: 'bottom-right',
  },
  [ToastsID.onOtherBid]: {
    title: 'toast.onOtherBid.title',
    description: 'toast.onOtherBid.description',
    status: 'info',
    duration: 9000,
    isClosable: true,
    position: 'bottom-right',
  },
  [ToastsID.onRoundWin]: {
    title: 'toast.onRoundWin.title',
    description: 'toast.onRoundWin.description',
    status: 'success',
    duration: 9000,
    isClosable: true,
    position: 'top',
  },
  [ToastsID.onRoundEnd]: {
    title: 'toast.onRoundEnd.title',
    description: 'toast.onRoundEnd.description',
    status: 'info',
    duration: 9000,
    isClosable: true,
    position: 'top',
  },
  [ToastsID.onNewRound]: {
    title: 'toast.onNewRound.title',
    description: 'toast.onNewRound.description',
    status: 'info',
    duration: 9000,
    isClosable: true,
    position: 'top',
  },
  [ToastsID.onACPIEnd]: {
    title: 'toast.onACPIEnd.title',
    description: 'toast.onACPIEnd.description',
    status: 'success',
    duration: 9000,
    isClosable: true,
    position: 'top',
  },
} as const);
