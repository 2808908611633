import { FC, ReactElement, useEffect } from 'react';

import {
  useEagerConnect,
  useDefaultConnect,
  useInactiveListener,
  useAppDispatch,
  useAppSelector,
} from 'src/hooks';
import { utilsActions, utilsSelectors } from 'src/slices';

export const ConnectProvider: FC = ({ children }) => {
  const dispatch = useAppDispatch();

  const hasTriedEager = useEagerConnect();
  const error = useDefaultConnect();
  useInactiveListener(!hasTriedEager);

  const disclosure = useAppSelector(utilsSelectors.getDisclosure);

  useEffect(() => {
    if (error) {
      dispatch(utilsActions.setLoaderSubtitle(error));
      disclosure.onOpen();
    }
  }, [dispatch, error, disclosure]);

  if (!hasTriedEager || error) {
    return null;
  }

  return children as ReactElement;
};
