import { FC } from 'react';

import { ACPISOn } from 'src/constants';
import { useAppSelector } from 'src/hooks';
import { contractsSelectors } from 'src/slices';

import { ACPIOneManager } from './ACPIOneManager';

export const ACPIManager: FC = ({ children }) => {
  const { currentACPI } = useAppSelector(contractsSelectors.getContracts);

  return (
    <>
      {currentACPI === ACPISOn.One && <ACPIOneManager />}
      {children}
    </>
  );
};
