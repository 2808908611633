import { useEffect, useState } from 'react';
import { Web3Provider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
import { Web3ReactContextInterface } from '@web3-react/core/dist/types';

import { injectedConnector, networkConnector } from 'src/connectors';
import { DEFAULT_PROVIDER } from 'src/constants';

type useActiveWeb3React = () => Web3ReactContextInterface<Web3Provider>;

export const useActiveWeb3React: useActiveWeb3React = () => {
  const context = useWeb3React<Web3Provider>();
  const contextNetwork = useWeb3React<Web3Provider>(DEFAULT_PROVIDER);

  return context.active ? context : contextNetwork;
};

type useDefaultConnect = () => string | undefined;

export const useDefaultConnect: useDefaultConnect = () => {
  const { active, error, activate } = useWeb3React(DEFAULT_PROVIDER);

  useEffect(() => {
    const defaultConnect = async () => {
      await activate(networkConnector);
    };

    if (!active) {
      defaultConnect();
    }
  }, [active, activate]);

  return error?.message;
};

type useEagerConnect = () => boolean;

export const useEagerConnect: useEagerConnect = () => {
  const { active, activate } = useWeb3React<Web3Provider>();
  const [hasTriedEager, setTriedEager] = useState<boolean>(false);

  useEffect(() => {
    const eagerConnect = async () => {
      const isAuthorized = await injectedConnector.isAuthorized();
      if (isAuthorized) {
        await activate(injectedConnector);
      }
      setTriedEager(true);
    };

    if (!active && !hasTriedEager) {
      eagerConnect();
    }
  }, [active, hasTriedEager, activate]);

  return hasTriedEager;
};

type useInactiveListener = (suppress?: boolean) => void;

export const useInactiveListener: useInactiveListener = (suppress = false) => {
  const { active, error, activate } = useWeb3React();

  useEffect(() => {
    const { ethereum } = window;

    if (ethereum && ethereum.on && !active && !error && !suppress) {
      const handleChainChanged = async () => {
        await activate(injectedConnector);
      };

      const handleAccountsChanged = async (accounts: string[]) => {
        if (accounts.length > 0) {
          await activate(injectedConnector);
        }
      };

      ethereum.on('chainChanged', handleChainChanged);
      ethereum.on('accountsChanged', handleAccountsChanged);

      return () => {
        if (ethereum.off) {
          ethereum.off('chainChanged', handleChainChanged);
          ethereum.off('accountsChanged', handleAccountsChanged);
        }
      };
    }
    return undefined;
  }, [active, error, suppress, activate]);
};
