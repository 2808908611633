import {
  AvalancheSVG,
  BinanceSVG,
  EthereumSVG,
  GnosisChainSVG,
  POASVG,
  PolygonSVG,
  xDaiSVG,
} from 'src/assets';
import { asConst } from 'src/utils';

import { LinksID } from './links';

export enum TokensID {
  Avalanche,
  Binance,
  Ethereum,
  Gnosis,
  POA,
  Polygon,
  xDai,
}

type Token = {
  name: string;
  svg: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  padding: number;
  link: LinksID;
};

export const TOKENS = asConst<Record<TokensID, Token>>()({
  [TokensID.Avalanche]: {
    name: 'Avalanche',
    svg: AvalancheSVG,
    padding: 1,
    link: LinksID.Avalanche,
  },
  [TokensID.Binance]: {
    name: 'Binance',
    svg: BinanceSVG,
    padding: 2,
    link: LinksID.Binance,
  },
  [TokensID.Ethereum]: {
    name: 'Ethereum',
    svg: EthereumSVG,
    padding: 2,
    link: LinksID.Ethereum,
  },
  [TokensID.Gnosis]: {
    name: 'Gnosis Chain',
    svg: GnosisChainSVG,
    padding: 1,
    link: LinksID.Gnosis,
  },
  [TokensID.POA]: {
    name: 'POA Network',
    svg: POASVG,
    padding: 2,
    link: LinksID.POA,
  },
  [TokensID.Polygon]: {
    name: 'Polygon',
    svg: PolygonSVG,
    padding: 2,
    link: LinksID.Polygon,
  },
  [TokensID.xDai]: {
    name: 'xDai',
    svg: xDaiSVG,
    padding: 0,
    link: LinksID.xDai,
  },
} as const);
