import { FC, useEffect } from 'react';
import { useDisclosure } from '@chakra-ui/react';

import { Loader } from 'src/components';
import { useAppDispatch } from 'src/hooks';
import { utilsActions } from 'src/slices';

export const LoaderManager: FC = () => {
  const dispatch = useAppDispatch();

  const disclosure = useDisclosure({ id: 'Loader', defaultIsOpen: true });

  useEffect(() => {
    dispatch(utilsActions.setDisclosure(disclosure));
  }, [dispatch, disclosure]);

  return <Loader disclosure={disclosure} />;
};
