import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Link } from 'src/constants';

import { useNetwork } from './useNetwork';

type useBlockExplorer = () => Link;

export const useBlockExplorer: useBlockExplorer = () => {
  const { blockExplorer, contractAddress } = useNetwork();
  const { t } = useTranslation();

  return useMemo(
    () => ({
      text: t('menu.viewOn', { name: blockExplorer.name }),
      href: `${blockExplorer.link}address/${contractAddress}`,
    }),
    [blockExplorer.link, blockExplorer.name, contractAddress, t]
  );
};
