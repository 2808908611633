import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ChakraProvider } from '@chakra-ui/react';
import { Web3ReactProvider, createWeb3ReactRoot } from '@web3-react/core';

import { App } from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';

import './localizations';

import { DEFAULT_PROVIDER } from 'src/constants';
import { RootProvider } from 'src/providers';
import { theme } from 'src/theme';
import { getLibrary } from 'src/utils';

import { store } from './store';

const Web3ReactDefaultProvider = createWeb3ReactRoot(DEFAULT_PROVIDER);

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <Web3ReactDefaultProvider getLibrary={getLibrary}>
        <Web3ReactProvider getLibrary={getLibrary}>
          <ChakraProvider theme={theme}>
            <RootProvider>
              <App />
            </RootProvider>
          </ChakraProvider>
        </Web3ReactProvider>
      </Web3ReactDefaultProvider>
    </Provider>
  </StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
