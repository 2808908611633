import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { BsTwitter, BsGithub, BsMedium, BsTelegram } from 'react-icons/bs';
import { Box, MenuGroup, MenuItem } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';

import { LINKS, LinksID } from 'src/constants';

import { LinkAs } from '../LinkAs';

const CommandLink: FC = () => {
  return (
    <Box display={'flex'} alignContent={'center'}>
      <ExternalLinkIcon />
    </Box>
  );
};

export const MenuLinks: FC = () => {
  const { t } = useTranslation();

  return (
    <MenuGroup title={t('menu.socialNetworks')}>
      <LinkAs
        link={LINKS[LinksID.Twitter]}
        isIcon={false}
        as={MenuItem}
        icon={<BsTwitter />}
        command={<CommandLink />}
      />
      <LinkAs
        link={LINKS[LinksID.Github]}
        isIcon={false}
        as={MenuItem}
        icon={<BsGithub />}
        command={<CommandLink />}
      />
      <LinkAs
        link={LINKS[LinksID.Telegram]}
        isIcon={false}
        as={MenuItem}
        icon={<BsTelegram />}
        command={<CommandLink />}
      />
      <LinkAs
        link={{ ...LINKS[LinksID.Medium], href: t(LINKS[LinksID.Medium].href) }}
        isIcon={false}
        as={MenuItem}
        icon={<BsMedium />}
        command={<CommandLink />}
      />
    </MenuGroup>
  );
};
