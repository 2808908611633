import { Interface } from '@ethersproject/abi';

import {
  ACPI1,
  ACPI1_ABI,
  ACPI2,
  ACPI2_ABI,
  ACPI3,
  ACPI3_ABI,
  ACPI4,
  ACPI4_ABI,
  REALT_ABI,
} from 'src/abis';
import { asConst } from 'src/utils';

export enum ACPISOn {
  One = 1,
  Two = 2,
  Three = 3,
  Four = 4,
}

export enum ACPISOff {
  Zero = 0,
  Five = 5,
  Six = 6,
}

export type ACPISID = ACPISOn | ACPISOff;
export type ACPISType = ACPI1 | ACPI2 | ACPI3 | ACPI4;

type ACPI = {
  name: string;
  interface: Interface;
};

export const ACPIS = asConst<Record<ACPISOn, ACPI>>()({
  [ACPISOn.One]: {
    name: 'acpiOneContract',
    interface: new Interface(ACPI1_ABI),
  },
  [ACPISOn.Two]: {
    name: 'acpiTwoContract',
    interface: new Interface(ACPI2_ABI),
  },
  [ACPISOn.Three]: {
    name: 'acpiThreeContract',
    interface: new Interface(ACPI3_ABI),
  },
  [ACPISOn.Four]: {
    name: 'acpiFourContract',
    interface: new Interface(ACPI4_ABI),
  },
} as const);

export const MAIN_INTERFACE = new Interface(REALT_ABI);
