import { FC } from 'react';
import {
  Button,
  Flex,
  Text,
  useBreakpoint,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useWeb3React } from '@web3-react/core';

import { useAppSelector, useNetwork } from 'src/hooks';
import { utilsSelectors } from 'src/slices';
import { formatAddress, formatBN } from 'src/utils';

import { ConnectButton } from '../ConnectButton';

export const WalletInfo: FC = () => {
  const { active, account } = useWeb3React();
  const balance = useAppSelector(utilsSelectors.getBalance);

  const breakpoint = useBreakpoint();
  const buttonSize = useBreakpointValue({ base: 'sm', md: 'md' });
  const { symbol } = useNetwork();

  if (!active) {
    return <ConnectButton buttonProps={{ size: buttonSize }} />;
  }

  return (
    <Flex
      alignItems={'center'}
      overflow={'hidden'}
      borderRadius={'xl'}
      borderWidth={'thin'}
    >
      {breakpoint !== 'base' && (
        <Text paddingX={3}>
          {`${formatBN(balance, 18, 1e16)} ${symbol.name}`}
        </Text>
      )}
      <Button _hover={{}} _focus={{}} _active={{}} size={buttonSize}>
        <Text>{formatAddress(account)}</Text>
      </Button>
    </Flex>
  );
};
