const inputRegex = RegExp(`^\\d{0,8}(?:\\\\[.])?\\d{0,8}$`);

type isInputValid = (nextUserInput: string) => boolean;

export const isInputValid: isInputValid = (nextUserInput) => {
  return (
    nextUserInput === '' ||
    inputRegex.test(nextUserInput.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'))
  );
};
