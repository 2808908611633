import { BigNumber } from '@ethersproject/bignumber';
import { formatUnits } from '@ethersproject/units';

type formatBN = (
  bigNumber: BigNumber,
  decimals?: number,
  precision?: number,
  trailling?: boolean
) => string;

export const formatBN: formatBN = (
  bigNumber,
  decimals,
  precision = 1e8,
  trailling = false
) => {
  const formattedBN = formatUnits(
    bigNumber.sub(bigNumber.mod(precision.toString())),
    decimals
  );
  return trailling ? formattedBN : formattedBN.replace(/.0+$/, '');
};
