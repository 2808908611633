import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useToast } from '@chakra-ui/react';

import type { PRICE, REALT } from 'src/abis';

import {
  ACPIS,
  ACPISID,
  ACPISOff,
  ACPISOn,
  ACPISType,
  DEFAULT_PROVIDER,
  MAIN_INTERFACE,
  NetworksID,
  TOASTS,
  ToastsID,
} from 'src/constants';
import {
  useActiveWeb3React,
  useAppDispatch,
  useAppSelector,
  useNetwork,
} from 'src/hooks';
import {
  contractsActions,
  contractsSelectors,
  ContractsState,
  utilsActions,
  utilsSelectors,
} from 'src/slices';
import { getContract, getToast } from 'src/utils';
import { useWeb3React } from '@web3-react/core';

const ContractsEvents: FC = () => {
  const dispatch = useAppDispatch();
  const { mainContract } = useAppSelector(contractsSelectors.getContracts);
  const { account, library } = useActiveWeb3React();

  const toast = useToast();
  const { t } = useTranslation();

  useEffect(() => {
    const onACPIChanged = async (currentACPI: ACPISID) => {
      if (!mainContract || currentACPI in ACPISOff) return;

      const address = await mainContract[
        ACPIS[currentACPI as ACPISOn].name
      ].call(undefined);
      const abi = ACPIS[currentACPI as ACPISOn].interface;

      const acpiContract = getContract(
        address,
        abi,
        library,
        account ?? undefined
      ) as ACPISType | undefined;

      dispatch(
        contractsActions.setACPIContract({
          currentACPI: currentACPI,
          acpiContract: acpiContract,
        })
      );

      toast(
        getToast(ToastsID.onACPIChanged, t, {
          title: t(TOASTS[ToastsID.onACPIChanged].title, {
            currentACPI: currentACPI,
          }),
        })
      );
    };

    mainContract?.on('ACPIChanged', onACPIChanged);

    return () => {
      mainContract?.off('ACPIChanged', onACPIChanged);
    };
  }, [dispatch, mainContract, account, library, toast, t]);

  return null;
};

export const ContractsManager: FC = () => {
  const dispatch = useAppDispatch();
  const { account, chainId, library } = useActiveWeb3React();
  const { library: defaultLibrary } = useWeb3React(DEFAULT_PROVIDER);

  const disclosure = useAppSelector(utilsSelectors.getDisclosure);
  const { contractAddress, price } = useNetwork();

  useEffect(() => {
    const initContracts = async () => {
      if (!contractAddress || !library || !chainId) {
        dispatch(contractsActions.resetContracts());
        return;
      }

      const mainContract = getContract<REALT>(
        contractAddress,
        MAIN_INTERFACE,
        library,
        account ?? undefined
      );

      if (!mainContract) {
        dispatch(contractsActions.resetContracts());
        return;
      }

      const priceContract = getContract<PRICE>(
        price.address,
        price.abi,
        // Hot fix for ChainLink not having Gnosis Testnet
        chainId === NetworksID.POASokol ? defaultLibrary : library,
        account ?? undefined
      );

      const contracts: ContractsState = {
        mainContract: mainContract,
        currentACPI: await mainContract.getACPI(),
        priceContract: priceContract,
      };

      if (contracts.currentACPI in ACPISOn) {
        const address = await mainContract[
          ACPIS[contracts.currentACPI as ACPISOn].name
        ].call(undefined);
        const abi = ACPIS[contracts.currentACPI as ACPISOn].interface;

        contracts.acpiContract = getContract<ACPISType>(
          address,
          abi,
          library,
          account ?? undefined
        );
      }

      const newPrice = await priceContract?.latestAnswer();

      dispatch(contractsActions.setContracts(contracts));
      dispatch(utilsActions.setPrice(newPrice));
    };
    disclosure.onOpen();
    initContracts().then(() => disclosure.onClose());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, contractAddress, price, library, chainId, account]);

  return (
    <>
      <ContractsEvents />
    </>
  );
};
