import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Box, BoxProps, forwardRef, Link } from '@chakra-ui/react';

import { Link as LinkType } from 'src/constants';

type LinkAs = {
  link: LinkType;
  isIcon?: boolean;
  variant?: string;
  boxProps?: BoxProps;
};

export const LinkAs = forwardRef<LinkAs, 'div'>(
  ({ link, isIcon = true, variant, ...boxProps }, ref) => {
    return (
      <Link href={link.href} variant={variant} isExternal={true}>
        <Box display={'flex'} alignItems={'center'}>
          <Box display={'flex'} alignItems={'center'} ref={ref} {...boxProps}>
            {link.text}
          </Box>
          {isIcon && <ExternalLinkIcon ml={1} />}
        </Box>
      </Link>
    );
  }
);
