import { FC } from 'react';
import { Box, ListIcon, ListItem, Text } from '@chakra-ui/react';

import { LINKS, TOKENS, TokensID } from 'src/constants';

import { LinkAs } from './LinkAs';
import { TokenIcon } from './TokenIcon';

type TokenListItem = {
  tokenId: TokensID;
};

export const TokenListItem: FC<TokenListItem> = ({ tokenId }) => {
  return (
    <ListItem>
      <Box display={'flex'} alignItems={'center'}>
        <ListIcon as={TokenIcon} tokenId={tokenId} />
        <LinkAs link={LINKS[TOKENS[tokenId].link]} as={Text} />
      </Box>
    </ListItem>
  );
};
