import { FC } from 'react';
import { Box } from '@chakra-ui/react';

type CommandLink = {
  icon: JSX.Element;
};

export const CommandLink: FC<CommandLink> = ({ icon }) => {
  return (
    <Box display={'flex'} alignContent={'center'}>
      {icon}
    </Box>
  );
};
