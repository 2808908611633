import { FC } from 'react';
import { Box } from '@chakra-ui/react';

export const Divider: FC = () => {
  return (
    <Box
      w={{ base: 'full', md: '0.5rem' }}
      h={{ base: '0.1rem', md: 'full' }}
      bgColor={'brand.400'}
      bgGradient={{
        base: 'linear(to-r, brand.100, brand.500)',
        md: 'linear(to-b, brand.100, brand.500)',
      }}
    />
  );
};
