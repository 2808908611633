import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
  config: {
    useSystemColorMode: false,
    initialColorMode: 'dark',
  },
  colors: {
    brand: {
      50: '#fff6dc',
      100: '#fde3b1',
      200: '#f9d183',
      300: '#f5bf54',
      400: '#f2ac26',
      500: '#d9930d',
      600: '#a97206',
      700: '#7a5103',
      800: '#493100',
      900: '#1c0f00',
    },
    gray: {
      50: '#ADADAD',
      100: '#858585',
      200: '#666666',
      300: '#525252',
      400: '#3D3D3D',
      500: '#333333',
      600: '#292929',
      700: '#1F1F1F',
      800: '#141414',
      900: '#0A0A0A',
    },
  },
  components: {
    Link: {
      variants: {
        branded: {
          color: 'brand.400',
        },
      },
    },
    Spinner: {
      defaultProps: {
        size: 'xl',
      },
      baseStyle: {
        color: 'brand.400',
      },
    },
    Button: {
      baseStyle: {
        borderRadius: 'xl',
      },
      defaultProps: {
        size: 'lg',
      },
      variants: {
        branded: {
          background: 'brand.800',
          color: 'brand.400',
          border: '1px solid transparent',
          _hover: {
            color: 'brand.300',
            borderColor: 'brand.300',
          },
        },
        badNetwork: {
          background: 'red.500',
          color: 'white',
          border: '1px solid transparent',
          _hover: {
            borderColor: 'white',
          },
        },
      },
    },
    IconButton: {
      baseStyle: {
        borderRadius: 'xl',
      },
      defaultProps: {
        size: 'lg',
      },
      variants: {
        branded: {
          background: 'brand.800',
          color: 'brand.400',
          border: '1px solid transparent',
          _hover: {
            color: 'brand.300',
            borderColor: 'brand.300',
          },
        },
      },
    },
    Text: {
      baseStyle: {
        fontWeight: 'semibold',
      },
    },
    Divider: {
      baseStyle: {
        borderColor: 'brand.400',
      },
    },
  },
});
