import { FC } from 'react';
import { Box, Heading, Stack } from '@chakra-ui/react';

import { TokensID } from 'src/constants';

import { TokenIcon } from './TokenIcon';

type HighestBid = {
  title: string;
  highestBid: string;
  highestBidder: string;
  symbol: string;
  tokenId: TokensID;
};

export const HighestBid: FC<HighestBid> = ({
  title,
  highestBid,
  highestBidder,
  tokenId,
  symbol,
}) => {
  return (
    <Box maxW={'sm'} overflow={'hidden'}>
      <Stack spacing={2}>
        <Heading size={'lg'}>{title}</Heading>
        <Box display={'flex'} alignItems={'center'}>
          <TokenIcon tokenId={tokenId} containerProps={{ size: 50 }} />
          <Heading size={'md'} ml={2}>
            {`${highestBid} ${symbol}`}
          </Heading>
        </Box>
        <Heading size={'md'}>{highestBidder}</Heading>
      </Stack>
    </Box>
  );
};
