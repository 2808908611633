import { Interface } from '@ethersproject/abi';
import { PRICE_ABI } from 'src/abis';
import { asConst } from 'src/utils';

import { TokensID } from './tokens';

export const INFURA_ID = '082fa2aa172048ffb8cc64afd14253e1';
export const DEFAULT_PROVIDER = 'authSike';
export const REG_SYMBOL = 'REG';

export enum NetworksID {
  Rinkeby = 0x4,
  BSC = 0x38,
  BSCTestnet = 0x61,
  Polygon = 0x89,
  PolygonMumbai = 0x13881,
  Gnosis = 0x64,
  POASokol = 0x4d,
}

export const DEFAULT_NETWORK = NetworksID.Gnosis;

export const SUPPORTED_NETWORKS: NetworksID[] = [
  NetworksID.Rinkeby,
  NetworksID.BSC,
  NetworksID.BSCTestnet,
  NetworksID.Polygon,
  NetworksID.PolygonMumbai,
  NetworksID.Gnosis,
  NetworksID.POASokol,
];

export const NETWORKS_URLS: Record<NetworksID, string> = {
  [NetworksID.Rinkeby]: `https://rinkeby.infura.io/v3/${INFURA_ID}`,
  [NetworksID.BSC]: 'https://bsc-dataseed.binance.org/',
  [NetworksID.BSCTestnet]: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
  [NetworksID.Polygon]: 'https://polygon-rpc.com/',
  [NetworksID.PolygonMumbai]: 'https://rpc-mumbai.maticvigil.com/',
  [NetworksID.Gnosis]: 'https://rpc.xdaichain.com/',
  [NetworksID.POASokol]: 'https://sokol.poa.network/',
};

export type Network = {
  name: string;
  contractAddress: string;
  symbol: { name: string; decimals: number };
  tokenId: TokensID;
  blockExplorer: { name: string; link: string };
  price: { address: string; abi: Interface };
};

export const NETWORKS = asConst<Record<NetworksID, Network>>()({
  [NetworksID.Rinkeby]: {
    name: 'Rinkeby',
    contractAddress: '0xaB5320119939cf1D371832e073cC914932b78073',
    symbol: { name: 'RIN', decimals: 18 },
    tokenId: TokensID.Ethereum,
    blockExplorer: {
      name: 'Etherscan',
      link: 'https://rinkeby.etherscan.io/',
    },
    price: {
      address: '0x8A753747A1Fa494EC906cE90E9f37563A8AF630e',
      abi: new Interface(PRICE_ABI),
    },
  },
  [NetworksID.BSC]: {
    name: 'BSC',
    contractAddress: '0xf93c133062A8FD52A65051BD121C4AEB192e8b4a',
    symbol: { name: 'BNB', decimals: 18 },
    tokenId: TokensID.Binance,
    blockExplorer: {
      name: 'BSCScan',
      link: 'https://bscscan.com/',
    },
    price: {
      address: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
      abi: new Interface(PRICE_ABI),
    },
  },
  [NetworksID.BSCTestnet]: {
    name: 'BSC Testnet',
    contractAddress: '0x5af03486463b82BdF8e0dde78BBAE3fDB77A2a37',
    symbol: { name: 'tBNB', decimals: 18 },
    tokenId: TokensID.Binance,
    blockExplorer: {
      name: 'BSCScan',
      link: 'https://testnet.bscscan.com/',
    },
    price: {
      address: '0x2514895c72f50D8bd4B4F9b1110F0D6bD2c97526',
      abi: new Interface(PRICE_ABI),
    },
  },
  [NetworksID.Polygon]: {
    name: 'Polygon',
    contractAddress: '0xf93c133062A8FD52A65051BD121C4AEB192e8b4a',
    symbol: { name: 'MATIC', decimals: 18 },
    tokenId: TokensID.Polygon,
    blockExplorer: {
      name: 'Polygonscan',
      link: 'https://polygonscan.com/',
    },
    price: {
      address: '0xAB594600376Ec9fD91F8e885dADF0CE036862dE0',
      abi: new Interface(PRICE_ABI),
    },
  },
  [NetworksID.PolygonMumbai]: {
    name: 'Mumbai',
    contractAddress: '0x2Bd2a7Aa5670D62a8Ac8f8Fe9396b28012855AB8',
    symbol: { name: 'mMATIC', decimals: 18 },
    tokenId: TokensID.Polygon,
    blockExplorer: {
      name: 'Polygonscan',
      link: 'https://mumbai.polygonscan.com/',
    },
    price: {
      address: '0xd0D5e3DB44DE05E9F294BB0a3bEEaF030DE24Ada',
      abi: new Interface(PRICE_ABI),
    },
  },
  [NetworksID.Gnosis]: {
    name: 'Gnosis',
    contractAddress: '0xf93c133062A8FD52A65051BD121C4AEB192e8b4a',
    symbol: { name: 'xDAI', decimals: 18 },
    tokenId: TokensID.Gnosis,
    blockExplorer: {
      name: 'Blockscout',
      link: 'https://blockscout.com/xdai/mainnet/',
    },
    price: {
      address: '0x678df3415fc31947dA4324eC63212874be5a82f8',
      abi: new Interface(PRICE_ABI),
    },
  },
  [NetworksID.POASokol]: {
    name: 'POA Sokol',
    contractAddress: '0x5af03486463b82BdF8e0dde78BBAE3fDB77A2a37',
    symbol: { name: 'SPOA', decimals: 18 },
    tokenId: TokensID.Ethereum,
    blockExplorer: {
      name: 'Blockscout',
      link: 'https://blockscout.com/poa/sokol/',
    },
    price: {
      address: '0x678df3415fc31947dA4324eC63212874be5a82f8',
      abi: new Interface(PRICE_ABI),
    },
  },
} as const);
