import { FC } from 'react';
import { Box, Heading, Image, Stack, useDisclosure } from '@chakra-ui/react';

import { REGPNG } from 'src/assets';
import { REG_SYMBOL } from 'src/constants';

import { Loader } from './Loader';

type InAuction = {
  title: string;
  amount: number;
};

export const InAuction: FC<InAuction> = ({ title, amount }) => {
  const disclosure = useDisclosure({
    id: 'displayREG',
    defaultIsOpen: false,
  });

  return (
    <Box maxW={'sm'} overflow={'hidden'}>
      <Loader
        disclosure={disclosure}
        modalOptions={{ closeOnOverlayClick: true }}
      />
      <Stack spacing={2}>
        <Heading size={'lg'}>{title}</Heading>
        <Box display={'flex'} alignItems={'center'}>
          <Image
            src={REGPNG}
            boxSize={'50px'}
            objectFit={'fill'}
            onClick={disclosure.onToggle}
          />
          <Heading size={'md'} ml={2}>{`${amount} ${REG_SYMBOL}`}</Heading>
        </Box>
      </Stack>
    </Box>
  );
};
