import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { VscDebugDisconnect } from 'react-icons/vsc';
import {
  Button,
  ButtonProps,
  Heading,
  List,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useBreakpointValue,
  useDisclosure,
  UseDisclosureReturn,
} from '@chakra-ui/react';

import { LINKS, LinksID, TokensID } from 'src/constants';

import { LinkAs } from '../LinkAs';
import { TokenListItem } from '../TokenListItem';

type BadNetworkButton = {
  disclosure: UseDisclosureReturn;
  buttonProps?: ButtonProps;
};

export const BadNetworkButton: FC<BadNetworkButton> = ({ buttonProps }) => {
  const modalSize = useBreakpointValue({ base: 'xs', md: 'md' });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();

  return (
    <>
      <Button
        variant={'badNetwork'}
        onClick={onOpen}
        leftIcon={<VscDebugDisconnect size={'1.5em'} />}
        isFullWidth={true}
        isTruncated={true}
        {...buttonProps}
      >
        {t('badNetwork')}
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered={true}
        size={modalSize}
        autoFocus={false}
        returnFocusOnClose={false}
      >
        <ModalOverlay backdropFilter={'blur(10px)'} />
        <ModalContent
          width={'auto'}
          justifyContent={'center'}
          borderRadius={'20'}
        >
          <ModalHeader>
            <Heading size={'lg'}>{t('modal.badNetwork.header')}</Heading>
          </ModalHeader>
          <ModalBody justifyContent={'center'}>
            <Text fontSize={'xl'} mb={3}>
              {t('modal.badNetwork.body')}
            </Text>
            <List spacing={3}>
              <TokenListItem tokenId={TokensID.Binance} />
              <TokenListItem tokenId={TokensID.Gnosis} />
              <TokenListItem tokenId={TokensID.Polygon} />
            </List>
          </ModalBody>
          <ModalFooter justifyContent={'center'}>
            <LinkAs
              link={{
                text: t(LINKS[LinksID.Blockchain].text),
                href: t(LINKS[LinksID.Blockchain].href),
              }}
              variant={'branded'}
              as={Text}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
