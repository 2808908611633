import { FC, useEffect } from 'react';

import { useActiveWeb3React, useAppDispatch, useAppSelector } from 'src/hooks';
import { contractsSelectors, utilsActions, utilsSelectors } from 'src/slices';

export const BlockManager: FC = () => {
  const dispatch = useAppDispatch();
  const { account, library, chainId } = useActiveWeb3React();

  const balance = useAppSelector(utilsSelectors.getBalance);
  const price = useAppSelector(utilsSelectors.getPrice);
  const { priceContract } = useAppSelector(contractsSelectors.getContracts);

  useEffect(() => {
    const onBlockUpdate = async (blockNumber: number) => {
      dispatch(utilsActions.setBlockNumber(blockNumber));
      if (library) {
        if (priceContract && priceContract.provider === library) {
          const newPrice = await priceContract.latestAnswer();
          if (!price?.eq(newPrice)) {
            dispatch(utilsActions.setPrice(newPrice));
          }
        }
        if (account) {
          const newBalance = await library.getBalance(account);
          if (!balance?.eq(newBalance)) {
            dispatch(utilsActions.setBalance(newBalance));
          }
        }
      }
    };

    library?.on('block', onBlockUpdate);

    return () => {
      library?.off('block', onBlockUpdate);
    };
  }, [dispatch, account, library, chainId, balance, priceContract, price]);

  return null;
};
