import { MenuItemOption, MenuOptionGroup } from '@chakra-ui/react';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export const MenuLanguage: FC = () => {
  const { i18n, t } = useTranslation();
  const currentLanguage = i18n.language;

  const changeLanguage = useCallback(
    (lang: string) => {
      if (lang !== currentLanguage) {
        i18n.changeLanguage(lang);
      }
    },
    [i18n, currentLanguage]
  );

  return (
    <MenuOptionGroup
      title={t('menu.language')}
      type={'radio'}
      defaultValue={i18n.language}
    >
      <MenuItemOption
        value={'fr'}
        closeOnSelect={false}
        onClick={() => changeLanguage('fr')}
      >
        {'🇫🇷 Français'}
      </MenuItemOption>
      <MenuItemOption
        value={'en'}
        closeOnSelect={false}
        onClick={() => changeLanguage('en')}
      >
        {'🇺🇸 English'}
      </MenuItemOption>
      <MenuItemOption
        value={'es'}
        closeOnSelect={false}
        onClick={() => changeLanguage('es')}
      >
        {'🇪🇸 Spanish'}
      </MenuItemOption>
    </MenuOptionGroup>
  );
};
