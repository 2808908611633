import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UseDisclosureReturn } from '@chakra-ui/react';
import { BigNumber } from '@ethersproject/bignumber';

export type UtilsState = {
  disclosure: UseDisclosureReturn;
  loaderSubtitle?: string;
  blockNumber: number;
  balance: BigNumber;
  price: BigNumber;
};

const initialDisclosure = {
  isOpen: true,
  onOpen: () => undefined,
  onClose: () => undefined,
  onToggle: () => undefined,
  isControlled: false,
  getButtonProps: () => undefined,
  getDisclosureProps: () => undefined,
};

const initialState: UtilsState = {
  disclosure: initialDisclosure,
  loaderSubtitle: undefined,
  blockNumber: 0,
  balance: BigNumber.from(0),
  price: BigNumber.from(0),
};

const utilsSlice = createSlice({
  name: 'utils',
  initialState,
  reducers: {
    setUtils: (_state, action: PayloadAction<UtilsState>) => action.payload,
    setDisclosure: (state, action: PayloadAction<UseDisclosureReturn>) => {
      state.disclosure = action.payload;
    },
    setLoaderSubtitle: (state, action: PayloadAction<string | undefined>) => {
      state.loaderSubtitle = action.payload;
    },
    setBlockNumber: (state, action: PayloadAction<number>) => {
      state.blockNumber = action.payload;
    },
    setBalance: (state, action: PayloadAction<BigNumber>) => {
      state.balance = action.payload;
    },
    setPrice: (state, action: PayloadAction<BigNumber | undefined>) => {
      state.price = action.payload ?? BigNumber.from(0);
    },
    resetUtils: () => initialState,
  },
});

const stateSelector = createSelector(
  ({ utils }: { utils: UtilsState }) => utils,
  (state) => state
);

export const utilsSelectors = {
  getUtils: createSelector(stateSelector, (state) => state),
  getDisclosure: createSelector(stateSelector, (state) => state.disclosure),
  getLoaderSubtitle: createSelector(
    stateSelector,
    (state) => state.loaderSubtitle
  ),
  getBlockNumber: createSelector(stateSelector, (state) => state.blockNumber),
  getBalance: createSelector(stateSelector, (state) => state.balance),
  getPrice: createSelector(stateSelector, (state) => state.price),
};

export const { actions: utilsActions, reducer: utilsReducer } = utilsSlice;
