import { TFunction } from 'react-i18next';
import { UseToastOptions } from '@chakra-ui/react';

import { TOASTS, ToastsID } from 'src/constants';

type getToast = (
  toastId: ToastsID,
  t: TFunction,
  toastOption?: UseToastOptions
) => UseToastOptions;

export const getToast: getToast = (toastId, t, toastOption) => {
  return {
    ...TOASTS[toastId],
    title: t(TOASTS[toastId].title),
    description: t(TOASTS[toastId].description),
    ...toastOption,
  };
};
