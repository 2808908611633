import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from 'src/store';

type useAppDispatch = () => Dispatch<AnyAction>;
type useAppSelector = TypedUseSelectorHook<RootState>;

export const useAppDispatch: useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: useAppSelector = useSelector;
