import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonProps,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';
import { AbstractConnector } from '@web3-react/abstract-connector';

import { MetaMaskSVG, WalletConnectSVG } from 'src/assets';
import { injectedConnector, walletConnectConnector } from 'src/connectors';
import { LINKS, LinksID } from 'src/constants';

import { BadNetworkButton } from './BadNetworkButton';
import { LinkAs } from '../LinkAs';

type ConnectorState = {
  injected?: boolean;
  walletConnect?: boolean;
};

type ConnectButton = { buttonProps?: ButtonProps };

export const ConnectButton: FC<ConnectButton> = ({ buttonProps }) => {
  const { error, activate } = useWeb3React();
  const [isLoading, setLoading] = useState<ConnectorState>({
    injected: false,
    walletConnect: false,
  });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const disclosure = useDisclosure();
  const modalSize = useBreakpointValue({ base: 'xs', md: 'md' });
  const { t } = useTranslation();

  const isUnsupportedChainIdError = error instanceof UnsupportedChainIdError;

  useEffect(() => {
    return () => {
      onClose();
    };
  }, [onClose]);

  const activateConnector = useCallback(
    async (connector: AbstractConnector) => {
      await activate(connector);
    },
    [activate]
  );

  const activateInjected = useCallback(async () => {
    setLoading({ injected: true });
    await activateConnector(injectedConnector);
    setLoading({ injected: false });
  }, [activateConnector]);

  const activateWalletConnect = useCallback(async () => {
    setLoading({ walletConnect: true });
    await activateConnector(walletConnectConnector);
    setLoading({ walletConnect: false });
  }, [activateConnector]);

  return (
    <>
      {isUnsupportedChainIdError ? (
        <BadNetworkButton buttonProps={buttonProps} disclosure={disclosure} />
      ) : (
        <Button
          onClick={onOpen}
          variant={'branded'}
          isTruncated={true}
          isFullWidth={true}
          {...buttonProps}
        >
          <Text isTruncated={true}>{t('connectWallet')}</Text>
        </Button>
      )}
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered={true}
        size={modalSize}
        autoFocus={false}
        returnFocusOnClose={false}
      >
        <ModalOverlay />
        <ModalContent borderRadius={'xl'}>
          <ModalHeader textAlign={'center'}>{t('connectWallet')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={5}>
              <Button
                variant={'branded'}
                isLoading={isLoading.injected}
                loadingText={t('initializing')}
                justifyContent={isLoading.injected ? 'center' : 'space-between'}
                onClick={activateInjected}
                isFullWidth={true}
                rightIcon={<Icon as={MetaMaskSVG} boxSize={35} />}
              >
                {t('metaMask')}
              </Button>
              <Button
                variant={'branded'}
                isLoading={isLoading.walletConnect}
                loadingText={t('initializing')}
                justifyContent={
                  isLoading.walletConnect ? 'center' : 'space-between'
                }
                onClick={activateWalletConnect}
                isFullWidth={true}
                rightIcon={<Icon as={WalletConnectSVG} boxSize={35} />}
              >
                {t('walletConnect')}
              </Button>
            </Stack>
          </ModalBody>
          <ModalFooter justifyContent={'center'}>
            <LinkAs
              link={{
                text: t(LINKS[LinksID.Wallet].text),
                href: t(LINKS[LinksID.Wallet].href),
              }}
              variant={'branded'}
              as={Text}
              isIcon={true}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
