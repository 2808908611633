import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { castDraft } from 'immer';

import type { ACPI1, PRICE, REALT } from 'src/abis';
import type { ACPISID, ACPISType } from 'src/constants';

export type ContractsState<T extends ACPISType = ACPISType> = {
  mainContract?: REALT;
  currentACPI: ACPISID;
  acpiContract?: T;
  priceContract?: PRICE;
};

const initialState: ContractsState = {
  mainContract: undefined,
  currentACPI: 0,
  acpiContract: undefined,
  priceContract: undefined,
};

const contractsSlice = createSlice({
  name: 'contracts',
  initialState,
  reducers: {
    setContracts: (_state, action: PayloadAction<ContractsState>) =>
      action.payload,
    setACPIContract: (
      state,
      action: PayloadAction<Omit<ContractsState, 'mainContract'>>
    ) => {
      state.currentACPI = action.payload.currentACPI;
      state.acpiContract = castDraft(action.payload.acpiContract);
    },
    resetContracts: () => initialState,
  },
});

const stateSelector = createSelector(
  ({ contracts }: { contracts: ContractsState }) => contracts,
  (state) => state
);

export const contractsSelectors = {
  getContracts: createSelector(stateSelector, (state) => state),
  getACPIOneContracts: createSelector(
    stateSelector,
    (state) => state as ContractsState<ACPI1>
  ),
};

export const { actions: contractsActions, reducer: contractsReducer } =
  contractsSlice;
