import { getAddress, isAddress } from '@ethersproject/address';

type formatAddress = (address?: string | null) => string;

export const formatAddress: formatAddress = (address) => {
  if (!address || !isAddress(address)) {
    return '0x0';
  }
  const checkedAddress = getAddress(address);
  return `${checkedAddress.substring(0, 6)}...${checkedAddress.substring(
    checkedAddress.length - 4
  )}`;
};
