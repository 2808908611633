import { FC } from 'react';
import {
  Box,
  IconButton,
  Menu,
  MenuButton as MenuButtonChakra,
  MenuDivider,
  MenuList,
  useBreakpointValue,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';

import { MenuLanguage } from './MenuLanguage';
import { MenuLinks } from './MenuLinks';
import { MenuHeader } from './MenuHeader';

export const HeaderMenu: FC = () => {
  const buttonSize = useBreakpointValue({ base: 'sm', md: 'md' });

  return (
    <Box>
      <Menu autoSelect={false}>
        <MenuButtonChakra
          as={IconButton}
          aria-label={'Options'}
          icon={<HamburgerIcon />}
          variant={'outline'}
          size={buttonSize}
        />
        <MenuList>
          <MenuHeader />
          <MenuDivider />
          <MenuLinks />
          <MenuDivider />
          <MenuLanguage />
        </MenuList>
      </Menu>
    </Box>
  );
};
